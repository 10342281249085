<script setup lang="ts">
import { Icon } from "@/components/Ui";
import ToggleLanguage from "@/components/Layout/ToggleLanguage.vue";
import PstClock from "@/components/Layout/PstClock.vue";
import { onSocial } from "@/helpers/events";

interface Props {
  offset?: boolean;
}

const footerNavItems = [
  // {
  //   title: "nav.whitelabel",
  //   path: "/whitelabel",
  // },
  {
    title: "nav.private",
    href: "https://pst.net/private",
  },
  {
    title: "nav.pulse",
    href: "https://pulse.pst.net",
  },
  {
    title: "nav.affiliate",
    href: "https://pst.net/affiliate",
  },
  {
    title: "nav.blog",
    href: "https://blog.pst.net",
  },
  {
    title: "nav.legal",
    href: "https://pst.net/legal",
  },
  {
    title: "nav.faq",
    href: "https://pst.net/faq",
  },
];

const props = defineProps<Props>();
</script>
<template>
  <footer
    :class="[footer.root, footer[`root-offset-${props.offset || false}`]]"
  >
    <div
      :class="footer.wrapper"
      class="container"
    >
      <div :class="footer.top">
        <a
          href="https://pst.net"
          aria-label="PST.NET - Virtual cards for payments. Crypto-fiat exchange"
        >
          <Icon
            name="pst-logo"
            path="./"
            :class="footer.logo"
          />
        </a>
        <ul :class="footer.social">
          <li>
            <a
              href="https://pst.net/reviews"
              :class="footer.review"
            >
              {{ $t("nav.reviews") }}
            </a>
          </li>
          <li
            :class="footer['social-link']"
            @click="
              onSocial('footer-telegram', 'telegram', false, $i18n.locale)
            "
          >
            <Icon name="telegram-filled" />
          </li>
          <li
            :class="footer['social-link']"
            @click="onSocial('footer-twitter', 'twitter', false, $i18n.locale)"
          >
            <Icon name="x" />
          </li>
        </ul>
      </div>

      <div :class="footer.bottom">
        <ToggleLanguage
          color="light"
          size="large"
          view="row"
          position="footer"
          chevron
          :class="footer.language"
        />
        <ul :class="footer.nav">
          <li
            v-for="(item, index) in footerNavItems"
            :key="index"
          >
            <a
              :href="item.href"
              :title="$t(item.title)"
              target="_blank"
            >
              {{ $t(item.title) }}
            </a>
          </li>
        </ul>
      </div>

      <div :class="footer.disclaimer">
        <!--noindex-->
        Facebook and the Facebook logo are trademarks of Meta Platforms, Inc,
        registered in the U.S. and other countries and regions. Google, Google
        Ads and the Google logo are trademarks of Google, LLC., registered in
        the U.S. and other countries and regions. PST solutions may not be
        available to all customers. Terms and conditions apply and are subject
        to change. PST Cards issued by Sutton Bank pursuant to a license by Visa
        U.S.A. Inc. Visa is a registered trademark of Visa, U.S.A. Inc. All
        other trademarks and service marks belong to their respective owners.
        PST's Mastercards are issued by Sutton Bank pursuant to a license by
        Mastercard International Incorporated. Mastercard is a registered
        trademark, and the circles design is a trademark of Mastercard
        International Incorporated.
        <!--/noindex-->
      </div>
      <a
        href="https://pst.net/pst"
        :class="footer.clock"
      >
        <PstClock />
      </a>
    </div>
  </footer>
</template>

<style lang="scss" module="footer">
.root {
  @apply pt-8 bg-neutral-900 z-10;

  &-offset-true {
    @apply pb-[6.75rem] md:pb-8;
  }

  &-offset-false {
    @apply pb-8;
  }
}

.wrapper {
  @apply flex flex-col gap-7;
}

.top {
  @apply flex flex-wrap gap-5 items-center justify-between pb-5 border-solid border-b border-neutral-800;
}

.bottom {
  @apply w-full flex flex-col gap-12;
  @apply md:items-center md:flex-row md:justify-between;
}

.nav {
  @apply flex gap-7 text-base col-span-1 flex flex-col text-[15px];

  @screen md {
    @apply gap-y-3 items-center flex-row justify-end flex-wrap text-[16px];
  }
  & a {
    @apply text-neutral-500 transition-all hover:text-neutral-200;
  }
}

.logo {
  @apply text-white w-[164px] col-span-1 mr-auto;
}

.social {
  @apply flex items-center gap-[10px];

  & li {
    @apply h-10 px-3 cursor-pointer flex items-center justify-center bg-neutral-800 rounded-[12px] hover:scale-[0.94] transition-all;
    @apply md:h-9;
  }

  &-link {
    @apply text-white;
    & svg {
      @apply w-4 h-4 min-h-[16px] min-w-[16px];
    }
  }
}

.review {
  @apply flex items-center gap-[5px] text-white text-[15px];
  @apply md:text-[16px] md:gap-2;
  //& svg {
  //  @apply text-[#2FC060];
  //  @apply w-4 h-4 min-w-[16px];
  //}
}

.language {
  @apply min-w-[240px] w-full;
  @apply md:w-fit;
}

.disclaimer {
  @apply text-neutral-700 text-sm col-span-1 text-justify;
  @apply md:col-span-4;
}

.clock {
  @apply w-fit ltr:md:ml-auto rtl:md:mr-auto;
}
</style>
